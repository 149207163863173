 $(document).ajaxStop(function(){
     $('a[name="workgroup_subscribe"]').each(function(i) {
         $(this).attr('onclick','translateWorkgroupMeetingLink(this, 1)');
     });
     $('a[name="workgroup_unsubscribe"]').each(function(i) {
         $(this).attr('onclick','translateWorkgroupMeetingLink(this, 0)');
     });
 });

 function translateWorkgroupMeetingLink(elm, state) {
     var lang = $('meta[name="language"]').attr('content');
     if (lang) {
         var elm = elm;
         var state = state;
         setTimeout(function(){
             if (state === 1) {
                 $(elm).attr('onclick','translateWorkgroupMeetingLink(this, 0)');
                 if (lang == 'nl-NL') {$(elm).text('Afmelden');}
                 if (lang == 'en-EN') {$(elm).text('Unsubscribe');}
                 if (lang == 'fr-BE') {$(elm).text('S\'excuser');}
             }
             if (state === 0) {
                 $(elm).attr('onclick','translateWorkgroupMeetingLink(this, 1)');
                 if (lang == 'nl-NL') {$(elm).text('Aanmelden');}
                 if (lang == 'en-EN') {$(elm).text('Subscribe');}
                 if (lang == 'fr-BE') {$(elm).text('S\'inscrire');}
             }
         }, 200);
     }
 }

 $(document).ajaxStop(function() {
     var huidigeLabel = $('.CrmSiteProfileMutationsEditPerson_roepnaam span').text();

     // Indien label Roepnaam is, dan veranderen in Voornaam.
     if (huidigeLabel === 'Roepnaam') {
         $('.CrmSiteProfileMutationsEditPerson_roepnaam span').text('Voornaam');
     }
 });


 $(document).ajaxStop(function() {
     var huidigeLabel = $('.meetingsNotFound').text();

     // Indien label Aucun réunions disponible. is, dan veranderen in Pas de session programmée pour l’instant.
     if (huidigeLabel === 'Aucun réunions disponible.') {
         $('.meetingsNotFound').text('Pas de session programmée pour l’instant');
     }
 });